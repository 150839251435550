<template>
  <div>
    <div v-if="skeleton"
         class="skeleton">
      <van-skeleton title
                    :row="20" />
    </div>
    <div class="details"
         v-else>
      <div class="article">
        <h3>{{ articleForm.name }}</h3>
        <div class="info">
          <span>{{ articleForm.author }}</span>
          <span>{{ articleForm.createTime }}</span>
        </div>
        <div class="content"
             v-html="articleForm.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      skeleton: true,
      articleForm: {}
    };
  },
  computed: {},
  created () { },
  mounted () {
    this.getArticle()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.getArticle()
        });
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    getArticle () {
      this.$api
        .get("/api/dccms/article/get", { id: this.$route.query.id })
        .then((res) => {
          this.skeleton = false
          this.articleForm = res.result || {}
        });
    }
  }
};
</script>
<style>
.van-checkbox__label,
.van-radio__label {
  font-size: 30px;
}
/* .content span {
  font-size: 28px !important;
}
.content p {
  font-size: 28px !important;
} */
</style>
<style scoped lang="less">
.comment_info {
  display: flex;
}
.send {
  width: 100%;
  height: 80px;
  border-radius: 40px;
  margin-top: 15px;
  border: 0;
  color: #fff;
  font-size: 30px;
  background: #ff6e03;
}
.comment_icon {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.comment_icon div {
  margin-right: 20px;
}
.comment {
  background: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  p {
    padding: 10px 0;
    font-size: 26px;
  }
  textarea {
    width: 100%;
    height: 300px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
  }
}
.rotate {
  transform: rotate(180deg);
}
.form_title {
  text-align: center;
  background: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
}
.back {
  position: fixed;
  right: 50px;
  bottom: 100px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  img {
    width: 80%;
    height: 80%;
    position: relative;
    top: -4px;
  }
}
.down {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.desc {
  background: #ffffff;
  padding-top: 35px;
  p {
    text-align: center;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-bottom: 20px;
    img {
      width: 20px;
      height: 10px;
      margin: 5px;
      margin-left: 13px;
      position: relative;
      top: 1px;
    }
  }
  ul {
    padding: 0 22px;
    padding-top: 28px;
    padding-bottom: 20px;
    li {
      margin-bottom: 20px;
      display: flex;
      .span1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b8b8b8;
      }
      .span2 {
        font-size: 0.32rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363636;
        padding-left: 0.26667rem;
        /* max-width: 7.06667rem; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        flex: 1;
        padding-right: 0.4rem;
      }
      .span3 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6e03;
        text-align: right;
      }
    }
  }
}
.details {
  background: #f9f9f9;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 100px;
  position: relative;
  .ad {
    margin: 20px 0;
    background: #fff;
    img {
      height: 400px;
      width: 100%;
    }
  }
  .btn {
    height: 88px;
    background: #ff6e03;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 88px;
    width: 90%;
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
  .back_btn {
    background: #fff;
    border: 1px solid #ccc;
    color: #363636;
    margin-top: 30px;
  }
  .sel {
    background: #fff;
    padding: 10px 20px;
    overflow: hidden;
    position: relative;
    .item {
      float: left;
      width: 50%;
      margin-bottom: 34px;
    }
    h5 {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #838383;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    input {
      width: 100%;
      height: 60px;
      // border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 10px;
      border: 0;
      border-bottom: 1px solid #ccc;
      padding-left: 10px;
      font-size: 32px;
    }
    textarea {
      width: 100%;
      padding: 10px;
      height: 120px;
      border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      font-size: 32px;
    }
  }
  .article {
    padding: 40px 23px;
    background: #fff;
    margin-top: 16px;
    .content {
      margin-top: 46px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
      line-height: 48px;
      text-align: justify;
      padding: 0 10px;
      box-sizing: border-box;
      span {
        font-size: 24px !important;
      }
      p {
        font-size: 24px !important;
      }
    }
    h3 {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      span {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c3c3c3;
      }
    }
  }
  .card {
    display: flex;
    height: 160px;
    align-items: center;
    background: #fff;
  }
  .text {
    flex: 1;
  }
  .img1 {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin-left: 43px;
    margin-right: 18px;
  }
  .p1 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #363636;
  }
  .p2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-top: 6px;
  }
  .img2 {
    width: 50px;
    height: 50px;
    margin-right: 26px;
    margin-top: 10px;
  }
}
</style>
