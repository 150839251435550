<template>
  <div>
    <div v-if="skeleton"
         class="skeleton">
      <van-skeleton title
                    :row="20" />
    </div>
    <div class="details"
         v-else>
      <div class="card">
        <img :src="form.businessCard.headPic"
             alt
             class="img1"
             @click="goCard" />
        <div class="text"
             @click="goCard">
          <p class="p1">{{ form.businessCard.name }}为您推荐</p>
          <p class="p2">{{ form.businessCard.shareWork }}</p>
        </div>
        <img src="../assets/images/图层 55.png"
             alt
             class="img2"
             @click="tel(form.businessCard.mobile)" />
      </div>
      <div class="desc">
        <p @click="rotate = !rotate">
          {{ !rotate ? "展开" : "收起" }}全部信息
          <img src="../assets/images/标题栏左箭头.png"
               alt
               :class="{ rotate: rotate }" />
        </p>
        <ul v-show="rotate">
          <li>
            <span class="span1">电话：</span>
            <span class="span2">{{ $getPhone(form.businessCard.mobile) }}</span>
            <span class="span3"
                  @click="tel(form.businessCard.mobile)">拨打</span>
          </li>
          <!-- <li>
            <span class="span1">微信：</span>
            <span class="span2">{{ form.businessCard.wechatNo }}</span>
            <span
              class="span3"
              v-clipboard:copy="form.businessCard.wechatNo"
              v-clipboard:success="onCopy"
            >复制</span>
          </li>-->
          <li>
            <span class="span1">邮箱：</span>
            <span class="span2">{{ form.businessCard.email }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.email"
                  v-clipboard:success="onCopy">复制</span>
          </li>
          <li>
            <span class="span1">公司：</span>
            <span class="span2">{{ form.businessCard.company }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.company"
                  v-clipboard:success="onCopy">复制</span>
          </li>
          <li>
            <span class="span1">地址：</span>
            <span class="span2">{{ form.businessCard.address }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.address"
                  v-clipboard:success="onCopy">复制</span>
          </li>
        </ul>
      </div>
      <div class="article">
        <h3>{{ form.article.name }}</h3>
        <div class="info">
          <span>{{ form.article.author }}</span>
          <span>{{ form.article.createTime }}</span>
        </div>
        <div class="content"
             v-html="form.article.content"></div>
      </div>
      <div class="ad"
           @click="adClick"
           v-if="form.ad.faceUrl">
        <img :src="form.ad.faceUrl"
             alt />
      </div>
      <p class="form_title">{{ form.from.name }}</p>
      <div class="sel"
           v-for="item in form.formItemEntryList"
           :key="item.id">
        <h5>
          <span v-if="item.isRequire"
                style="color: red">*</span>
          {{ item.name }}
        </h5>
        <div v-if="item.type == 0">
          <input type="text"
                 v-model="obj[item.number]" />
        </div>
        <div v-else-if="item.type == 1">
          <textarea v-model="obj[item.number]" />
        </div>
        <div v-else-if="item.type == 2">
          <radio-group v-model="obj[item.number]">
            <div class="item"
                 v-for="(i, index) in getValue(item.value, item.number)"
                 :key="index">
              <radio :name="i.value">{{ i.name }}</radio>
            </div>
          </radio-group>
        </div>
        <div v-else-if="item.type == 3"
             style>
          <van-checkbox-group v-model="obj[item.number]">
            <div class="item"
                 v-for="(i, index) in getValue(item.value, item.number)">
              <van-checkbox :name="i.value"
                            :key="index">{{
                i.name
              }}</van-checkbox>
            </div>
          </van-checkbox-group>
        </div>
        <div v-else-if="item.type == 4">
          <input type="text"
                 readonly
                 @click="showPicker = true"
                 v-model="obj[item.number + 'name']" />
          <van-icon name="arrow-down"
                    class="down" />
          <van-popup v-model="showPicker"
                     round
                     position="bottom">
            <van-picker title="标题"
                        show-toolbar
                        :columns="getValue(item.value, item.number)"
                        value-key="name"
                        @cancel="showPicker = false"
                        @confirm="onConfirm" />
          </van-popup>
        </div>
        <div v-else-if="item.type == 5">
          <input type="text"
                 readonly
                 @click="
              dateKey = item.number;
              showdatetimePicker = true;
            "
                 v-model="obj[item.number]" />
          <van-icon name="arrow-down"
                    class="down" />

          <van-popup v-model="showdatetimePicker"
                     round
                     position="bottom">
            <van-datetime-picker @cancel="showdatetimePicker = false"
                                 @confirm="dateConfirm"
                                 v-model="currentDate"
                                 :min-date="minDate"
                                 type="datetime"
                                 title="选择年月日" />
          </van-popup>
        </div>
      </div>
      <!-- <button class="btn"
              @click="submit"
              v-if="form.formItemEntryList"
              :loading="submitLoad"
              :disabled="disabledF">
        立即提交
      </button> -->
      <van-button class="btn" size="small"
                  @click="submit"
                  v-if="form.formItemEntryList"
                  :loading="submitLoad"
                  :disabled="disabledF">
        立即提交
      </van-button>

      <!-- <button class="btn back_btn" @click="back">返回首页</button> -->
      <qrcode v-model="show"
              :src="qrcode"
              text="您的信息我们已经收到，我们会尽快与您取得联系。长按识别二维码 立即联系"></qrcode>
      <!-- <div class="back" @click="back">
        <img src="../assets/images/首页.png" alt="" />
      </div>-->
      <!-- <div class="comment">
        <div class="comment_info">
          <p>评论</p>
          <div class="comment_icon">
            <div>
              <img src="../assets/images/点赞 (2).png" alt="" />
            </div>
            <div>
              <img src="../assets/images/收 藏.png" alt="" />
            </div>
            <div>
              <img
                src="../assets/images/分享 转发 发送至 箭头 方形 shar.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <textarea v-model="value"></textarea>
        <button class="send" @click="send">发送</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { RadioGroup, Radio } from "vant";
import qrcode from "@/components/qrcode";
import mixin from "../utils/share";
export default {
  mixins: [mixin],
  props: {},
  data () {
    return {
      radio: 1,
      radios: [],
      rotate: false,
      value: "",
      skeleton: true,
      disabledF: false,
      minDate: new Date(1970, 0, 1),
      show: false,
      qrcode: '',
      dateKey: "",
      currentDate: "",
      obj: {},
      showPicker: false,
      showdatetimePicker: false,
      query: {},
      submitLoad: false
    };
  },
  computed: {},
  created () { },
  mounted () {
    setTimeout(() => {
      this.Action(1);
    }, 3000);
  },
  watch: {},
  methods: {
    send () {
      this.$api.post("/crm/comment/add", {
        articleId: this.$route.query.articleId,
        nickname: this.$cookies.get("nickname"),
        headpic: this.$cookies.get("headpic"),
        openId: this.$cookies.get("openId"),
        content: this.value,
      });
    },
    back () {
      this.$router.push(`/?id=${this.$route.query.id}`);
    },
    goCard () {
      this.$router.push("/card?id=" + this.form.businessCard.id);
    },
    adClick () {
      if (this.form.ad.ladingPage) {
        this.Action(5);
        window.location.href = this.form.ad.ladingPage;
      }
    },
    dateConfirm (val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      let timeValue = `${year}-${month}-${day} ${hour}:${minute}`;
      this.obj[this.dateKey] = timeValue;
      this.showdatetimePicker = false;
    },
    onCopy (e) {
      this.$toast.success("复制成功");
    },
    submit () {
      let obj = {
        formId: this.form.from.id,
        value: [],
        pageId: this.$pageId,
      };
      // formId formItemEntryId value
      this.form.formItemEntryList.forEach((i) => {
        if (i.isRequire) {
          if (this.obj[i.number] == null) {
            this.$toast(`${i.name}为必填内容`);
            throw `${i.name}为必填内容`;
          }
        }
        for (const key in this.obj) {
          let element = this.obj[key];

          if (Array.isArray(element)) {
            element = element.join(",");
          }
          if (key == i.number) {
            obj.value.push({
              // formId: this.form.formId,
              // formItemEntryId: i.id,
              // value: element,
              //
              id: i.id,
              code: i.number,
              name: i.name,
              value: element,
            });
          }
        }
      });
      // this.Action(4);
      obj.value = JSON.stringify(obj.value);
      this.submitLoad = true
      this.$api
        .post("/api/crm/formValue/save", {
          ...obj,
          channelId: this.$route.query.channelId,
          ladingPageId: this.$route.query.id,
          pageId: this.$pageId,
          articleId: this.$route.query.articleId,
          action: 4,
          businessCardId: this.$route.query.businessCardId,
          url: window.location.href,
          openId: this.$cookies.get("openId"),
          unionId: this.$cookies.get("unionId"),
        })
        .then((res) => {
          if ((res.code === "C0000")) {
            // this.$toast({
            //   message: "您的信息我们已经收到，我们会尽快与您取得联系。",
            //   className: "_toast",
            // });
            this.show = true
            this.qrcode = this.form.businessCard.wechatQrCode;
            this.disabledF = true;
          }
          this.submitLoad = false
        }).catch(() => {
          this.submitLoad = false
        })
    },
    onConfirm (e) {
      this.obj[e.key + "name"] = e.name;
      this.obj[e.key] = e.value;
      this.showPicker = false;
    },
    getValue (val, key) {
      // 男；女
      let arr = [];
      if (val.indexOf(";") > -1) {
        arr = val.split(";");
      } else {
        arr = val.split("；");
      }
      return arr.map((i) => {
        return {
          value: i,
          name: i,
          key: key,
        };
      });
    },
    tel (value) {
      this.Action(8);
      window.location.href = `tel:${value}`;
    },
  },
  components: { RadioGroup, Radio, qrcode },
};
</script>
<style>
.van-checkbox__label,
.van-radio__label {
  font-size: 30px;
}
/* .content span {
  font-size: 28px !important;
}
.content p {
  font-size: 28px !important;
} */
</style>
<style scoped lang="less">
.comment_info {
  display: flex;
}
.send {
  width: 100%;
  height: 80px;
  border-radius: 40px;
  margin-top: 15px;
  border: 0;
  color: #fff;
  font-size: 30px;
  background: #ff6e03;
}
.comment_icon {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.comment_icon div {
  margin-right: 20px;
}
.comment {
  background: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  p {
    padding: 10px 0;
    font-size: 26px;
  }
  textarea {
    width: 100%;
    height: 300px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
  }
}
.rotate {
  transform: rotate(180deg);
}
.form_title {
  text-align: center;
  background: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
}
.back {
  position: fixed;
  right: 50px;
  bottom: 100px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  img {
    width: 80%;
    height: 80%;
    position: relative;
    top: -4px;
  }
}
.down {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.desc {
  background: #ffffff;
  padding-top: 35px;
  p {
    text-align: center;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-bottom: 20px;
    img {
      width: 20px;
      height: 10px;
      margin: 5px;
      margin-left: 13px;
      position: relative;
      top: 1px;
    }
  }
  ul {
    padding: 0 22px;
    padding-top: 28px;
    padding-bottom: 20px;
    li {
      margin-bottom: 20px;
      display: flex;
      .span1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b8b8b8;
      }
      .span2 {
        font-size: 0.32rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363636;
        padding-left: 0.26667rem;
        /* max-width: 7.06667rem; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        flex: 1;
        padding-right: 0.4rem;
      }
      .span3 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6e03;
        text-align: right;
      }
    }
  }
}
.details {
  background: #f9f9f9;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 100px;
  position: relative;
  .ad {
    margin: 20px 0;
    background: #fff;
    img {
      height: 400px;
      width: 100%;
    }
  }
  .btn:disabled{
    background: #eee;
    color: #333;
  }
  .btn {
    height: 88px;
    background: #ff6e03;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 88px;
    width: 90%;
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
  .back_btn {
    background: #fff;
    border: 1px solid #ccc;
    color: #363636;
    margin-top: 30px;
  }
  .sel {
    background: #fff;
    padding: 10px 20px;
    overflow: hidden;
    position: relative;
    .item {
      float: left;
      width: 50%;
      margin-bottom: 34px;
    }
    h5 {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #838383;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    input {
      width: 100%;
      height: 60px;
      // border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 10px;
      border: 0;
      border-bottom: 1px solid #ccc;
      padding-left: 10px;
      font-size: 32px;
    }
    textarea {
      width: 100%;
      padding: 10px;
      height: 120px;
      border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      font-size: 32px;
    }
  }
  .article {
    padding: 40px 23px;
    background: #fff;
    margin-top: 16px;
    .content {
      margin-top: 46px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
      line-height: 48px;
      text-align: justify;
      padding: 0 10px;
      box-sizing: border-box;
      span {
        font-size: 24px !important;
      }
      p {
        font-size: 24px !important;
      }
    }
    h3 {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      span {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c3c3c3;
      }
    }
  }
  .card {
    display: flex;
    height: 160px;
    align-items: center;
    background: #fff;
  }
  .text {
    flex: 1;
  }
  .img1 {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin-left: 43px;
    margin-right: 18px;
  }
  .p1 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #363636;
  }
  .p2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-top: 6px;
  }
  .img2 {
    width: 50px;
    height: 50px;
    margin-right: 26px;
    margin-top: 10px;
  }
}
</style>
